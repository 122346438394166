<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 text-center">
        <h5>Current Situation</h5>
      </div>
      <div class="cell small-12 medium-6 text-center">
        <h5>Future Situation</h5>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="current-cell">wAMD</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">DME</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">PDR</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">RVO</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="current-cell">CNV</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="future-cell">wAMD</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">DME</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">PDR</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">RVO</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="future-cell">CNV</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('byoviz')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Byooviz</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ byovizWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{  byovizDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ byovizCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('lucentis')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Lucentis</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ lucentisWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ lucentisCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x" v-if="drugs.includes('biosimilars 2')">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">biosimilars 2</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ biosimilarWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ biosimilarCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x">
      <div class="cell small-12 medium-1 pa">
        <span class="heading-cell text-right">Total</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalWamd | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalDme | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalPdr | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalRvo | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalCnv | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 medium-offset-1 pa">
        <span class="text-small">&pound;{{ totalWamdFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalDmeFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalPdrFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalRvoFuture | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-1 pa">
        <span class="text-small">&pound;{{ totalCnvFuture | numeralFormat('0,0[.]') }}</span>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-10">
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Total current situation:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">&pound;{{ totalCurrentSituation | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Total future situation:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">&pound;{{ totalFutureSituation | numeralFormat('0,0[.]') }}</span>
      </div>
      <div class="cell small-12 medium-2">
        <h5 class="text-right">Difference:</h5>
      </div>
      <div class="cell small-12 medium-2">
        <span class="input-fixed text-center">
          <i class="plain" v-if="differenceIsNegative">-</i>
          &pound;{{ displayDifference | numeralFormat('0,0[.]') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchPatientsTable',
  data() {
    return {
      differenceIsNegative: false,
      displayDifference: 0,
    };
  },
  computed: {
    drugs() {
      return this.$store.state.drugs;
    },
    timeframe() {
      return this.$store.state.timeframe;
    },
    lucentisWamd() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdLucentisYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizWamd() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdByovizYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarWamd() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisDme() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeLucentisYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizDme() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeByovizYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarDme() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisPdr() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrLucentisYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizPdr() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrByovizYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarPdr() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisRvo() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoLucentisYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizRvo() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoByovizYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarRvo() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    lucentisCnv() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvLucentisYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    byovizCnv() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvByovizYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvByovizYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvByovizYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    biosimilarCnv() {
      const yearOne = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOne;
      const yearTwo = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearTwo;
      const yearThree = this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearThree;
      const total = (yearOne + yearTwo + yearThree);
      return total;
    },
    totalWamd() {
      return (this.lucentisWamd + this.byovizWamd + this.biosimilarWamd);
    },
    totalDme() {
      return (this.lucentisDme + this.byovizDme + this.biosimilarDme);
    },
    totalPdr() {
      return (this.lucentisPdr + this.byovizPdr + this.biosimilarPdr);
    },
    totalRvo() {
      return (this.lucentisRvo + this.byovizRvo + this.biosimilarRvo);
    },
    totalCnv() {
      return (this.lucentisCnv + this.byovizCnv + this.biosimilarCnv);
    },
    totalCurrentSituation() {
      return (this.totalWamd + this.totalDme + this.totalPdr + this.totalRvo + this.totalCnv);
    },
    lucentisWamdFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostWamdLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisWamd;
    },
    byovizWamdFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostWamdByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizWamd;
    },
    biosimilarWamdFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostWamdBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarWamd;
    },
    lucentisDmeFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostDmeLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisDme;
    },
    byovizDmeFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostDmeByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizDme;
    },
    biosimilarDmeFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostDmeBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarDme;
    },
    lucentisPdrFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostPdrLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisPdr;
    },
    byovizPdrFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostPdrByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizPdr;
    },
    biosimilarPdrFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostPdrBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarPdr;
    },
    lucentisRvoFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostRvoLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisRvo;
    },
    byovizRvoFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostRvoByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizRvo;
    },
    biosimilarRvoFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostRvoBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarRvo;
    },
    lucentisCnvFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostCnvLucentisYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureLucentisCnv;
    },
    byovizCnvFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostCnvByovizYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureByovizCnv;
    },
    biosimilarCnvFuture() {
      if (this.timeframe === 1) {
        return this.$store.getters.switchPatientsCurrentCostCnvBiosimilarYearOneFuture;
      }
      return this.$store.getters.switchPatientsThreeYearFutureBiosimilarCnv;
    },
    totalWamdFuture() {
      return (this.lucentisWamdFuture + this.byovizWamdFuture + this.biosimilarWamdFuture);
    },
    totalDmeFuture() {
      return (this.lucentisDmeFuture + this.byovizDmeFuture + this.biosimilarDmeFuture);
    },
    totalPdrFuture() {
      return (this.lucentisPdrFuture + this.byovizPdrFuture + this.biosimilarPdrFuture);
    },
    totalRvoFuture() {
      return (this.lucentisRvoFuture + this.byovizRvoFuture + this.biosimilarRvoFuture);
    },
    totalCnvFuture() {
      return (this.lucentisCnvFuture + this.byovizCnvFuture + this.biosimilarCnvFuture);
    },
    totalFutureSituation() {
      return (this.totalWamdFuture + this.totalDmeFuture + this.totalPdrFuture + this.totalRvoFuture + this.totalCnvFuture);
    },
    totalDifference() {
      const difference = (this.totalCurrentSituation - this.totalFutureSituation);
      return difference;
    },
  },
  methods: {
    isDifferenceNegative() {
      const diff = (this.totalCurrentSituation - this.totalFutureSituation);
      if (diff > 0) this.differenceIsNegative = true;
      this.displayDifference = Math.abs(this.totalDifference);
    },
  },
  mounted() {
    this.isDifferenceNegative();
  },
};
</script>
